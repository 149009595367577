<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="2" sm="12">
                  <p class="text-dark">{{ $t('pump_install.pump_type') }} : </p>
                </b-col>
                <b-col lg="4" sm="12">
                    <p class="text-dark">{{ requisition.pump_type_id }}</p>
                </b-col>
                <b-col lg="2" sm="12">
                  <p class="text-dark">{{ $t('pump_install.requisition_id') }} : </p>
                </b-col>
                <b-col lg="4" sm="12">
                    <p class="text-dark">{{ requisition.requisition_id }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="2" sm="12">
                  <p class="text-dark">{{ $t('pump_install.remarks') }} : </p>
                </b-col>
                <b-col lg="4" sm="12">
                    <p class="text-dark">{{ ($i18n.locale === 'bn') ? requisition.remarks_bn : requisition.remarks }}</p>
                </b-col>
                <b-col lg="2" sm="12">
                  <p class="text-dark">{{ $t('pump_install.requisition_date') }} : </p>
                </b-col>
                <b-col lg="4" sm="12">
                    <p class="text-dark">{{ requisition.requisition_date | dateFormat  }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(updateRequsition)" @reset.prevent="reset" >
                      <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('select')+' '+$t('item') }}</p>
                      <hr style="margin-top:-15px">
                      <b-row v-for="(item,index) in requistion_details" :key="index">
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Category" vid="category_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="category_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('irrigation_config.category') }}
                              </template>
                              <b-form-input
                                plain
                                id="category_id"
                                :value="item.category"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Item" vid="item_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="item_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('irrigation_config.item') }}
                              </template>
                              <b-form-input
                                  plain
                                  id="item_id"
                                  :value="item.item_name"
                                  readonly
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Unit" vid="unit_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="unit_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('irrigation_config.unit') }}
                              </template>
                              <b-form-input
                                  plain
                                  id="unit_id"
                                  :value="item.measurement_unit"
                                  readonly
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="stock" vid="stock">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="stock"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('pump_install.stock') }}
                              </template>
                              <b-form-input
                                  readonly
                                  id="stock"
                                  v-model="item.stock_quantity"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Qty" vid="quantity" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12 p-0 m-0"
                              label-for="quantity"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('pump_install.request_quantity') }}
                              </template>
                              <b-form-input
                                id="quantity"
                                :value="item.stock_out_quantity"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Qty" vid="accept_quantity" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12 p-0 m-0"
                              label-for="accept_quantity"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('pump_install.accept_quantity') }}
                              </template>
                              <b-form-input
                                id="accept_quantity"
                                v-model.number="item.accept_quantity"
                                @input="checkStock(index)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { requisitionDetails, requisitionQuantityUpdate } from '../../api/routes'
import IrrigationMixin from '../../../../../mixins/irrigation-dropdown'

export default {
  name: 'FormLayout',
  mixins: [IrrigationMixin],
  props: ['application_id', 'requisition_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      requisition: '',
      requistion_details: []
    }
  },
  created () {
    this.getRequisitionDetails()
  },
  methods: {
    async getRequisitionDetails () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, requisitionDetails + '/' + this.$props.application_id + '/' + this.$props.requisition_id)
      if (result.success) {
        this.requisition = result.data.requistion
        this.requistion_details = this.formatList(result.data.requistion_details)
      }
      this.loading = false
    },
    async updateRequsition () {
      this.loading = true
      const result = await RestApi.postData(irriSchemeServiceBaseUrl, requisitionQuantityUpdate, this.requistion_details)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-2')
        this.$bvModal.hide('modal-1')
      }
    },
    getPumpTypeName (pumpTypeId) {
      const pumpTypeList = this.$store.state.IrriConfig.commonObj.pumpType
      const pumpType = pumpTypeList.find(pump => pump.value === pumpTypeId)
      return this.$i18n.locale === 'bn' ? pumpType.text_bn : pumpType.text_en
    },
    formatList (data) {
      if (this.$i18n.locale === 'bn') {
        const listData = data.map(item => {
          const tmpCategory = { category: item.category_name_bn }
          const tmpSubCategory = { sub_category: item.sub_category_name_bn }
          const tmpItemName = { item_name: item.item_name_bn }
          const tmpUnit = { measurement_unit: item.unit_name_bn }
          const tmpStock = { stock_quantity: item.stock_quantity }
          const tmpStockOut = { stock_out_quantity: item.stock_out_quantity }
          const acceptQuantity = { accept_quantity: item.stock_out_quantity }
          const requisitionId = { requisition_id: item.requisition_id }
          return Object.assign({}, tmpCategory, tmpSubCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, acceptQuantity, requisitionId)
        })
        return listData
      } else {
        const listData = data.map(item => {
          const tmpCategory = { category: item.category_name }
          const tmpSubCategory = { sub_category: item.sub_category_name }
          const tmpItemName = { item_name: item.item_name }
          const tmpUnit = { measurement_unit: item.unit_name }
          const tmpStock = { stock_quantity: item.stock_quantity }
          const tmpStockOut = { stock_out_quantity: item.stock_out_quantity }
          const acceptQuantity = { accept_quantity: item.stock_out_quantity }
          const requisitionId = { requisition_id: item.requisition_id }
          return Object.assign({}, tmpCategory, tmpSubCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, acceptQuantity, requisitionId)
        })
        return listData
      }
    },
    checkStock (index) {
      const requisition = this.requisition.items[index]
      if (requisition.quantity > requisition.stock) {
        requisition.quantity = requisition.stock
      }
    }
  }
}
</script>
