<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card v-if="requistion.status === 1 || requistion.status === 6 || requistion.approval_status === 2">
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                    <h5 class='complain-title'>{{ $t('pump_install.requisition_details') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('pump_install.organization') }} : </p>
                      </b-col>
                      <b-col lg="8" sm="12">
                         <p class="text-dark">{{ organizationName }}</p>
                      </b-col>
                      <b-col lg="2" sm="12">
                         <b-button class="btn btn-outline-success" variant="iq-bg-success" size="sm" title="Download"><i class="ri-download-cloud-fill"></i> </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('pump_install.requisition_date') }} : </p>
                      </b-col>
                      <b-col lg="10" sm="12">
                         <p class="text-dark">{{ requistion.requisition_date | dateFormat }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('pump_install.requisition_id') }} : </p>
                      </b-col>
                      <b-col lg="10" sm="12">
                         <p class="text-dark">{{ requistion.requisition_id }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table bordered :fields="columns" :items="items"></b-table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="2" sm="12">
                    <p class="text-dark">{{ $t('pump_install.remarks') }} : </p>
                  </b-col>
                  <b-col lg="10" sm="12">
                      <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? requistion.remarks_bn : requistion.remarks }}</p>
                  </b-col>
                </b-row>
                <b-row v-if="approval_notes.length > 0">
                    <b-col lg="12" sm="12" class="text-center mb-4">
                    <h5 class='complain-title'>{{ $t('irri_pump_main.approval_note') }}</h5>
                    </b-col>
                </b-row>
                <b-row v-if="approval_notes.length > 0">
                  <table class="table table-bordered" style="width: 100%;margin: 0px 12px;margin-bottom: 20px;">
                    <tr>
                        <th>{{ $t('irri_pump_main.note') }}</th>
                        <th>{{ $t('irri_pump_main.sender_name') }}</th>
                    </tr>
                    <tr v-for="(approvalNote,index) in approval_notes" :key="index">
                        <td>{{ ($i18n.locale === 'bn') ? approvalNote.note_bn : approvalNote.note }}</td>
                        <td>{{ getOfficeName(approvalNote.sender_id) }}</td>
                    </tr>
                  </table>
                </b-row>
                <b-row>
                  <b-col lg="8" sm="12">
                    <b-button v-if="requistion.status === 1 || requistion.status === 6 || requistion.approval_status === 2" type="button" variant="primary" class="mr-2" @click="approveRequisition"><i class="ri-check-line"></i>{{ $t('globalTrans.approve')}}</b-button>
                    <b-button v-if="requistion.status === 1 || requistion.approval_status === 2" type="button" v-b-modal.modal-2 variant="primary" class="mr-2" @click="forwardAllocate(1)"><i class="ri-arrow-up-line"></i>{{ $t('pump_install.forward')}}</b-button>
                    <b-button v-if="requistion.status === 1 || requistion.approval_status === 2" type="button" v-b-modal.modal-2 variant="primary" class="mr-2" @click="forwardAllocate(2)"><i class="ri-arrow-down-line"></i>{{ $t('pump_install.allocate')}}</b-button>
                    <b-button v-if="requistion.status === 1 || requistion.approval_status === 2" type="button" v-b-modal.modal-2 variant="primary" class="mr-2" @click="editConfirmation()"><i class="ri-edit-line"></i>{{ $t('pump_install.edit')}}</b-button>
                  </b-col>
                  <div class="col text-right">
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                </b-row>
            </template>
          </iq-card>
          <iq-card v-else>
            <template>
              <b-row>
                <b-col lg="12" sm="12">
                  <p class="text-dark"><b>{{ $t('pump_install.requisition_not_found') }}</b></p>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-modal v-if="isEdit" id="modal-2" size="xl" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
          <EditRequisition v-if="modalType === 1" :application_id="application_id" :requisition_id="requisition_id" :key="id"/>
          <ForwardAllocate v-if="modalType === 2" :application_id="application_id" :requisition_id="requisition_id" :forward_type="forward_type" :key="id" />
        </p>
      </b-modal>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { requisitionDetails, requisitionApprove } from '../../api/routes'
import EditRequisition from './EditRequisition'
import ForwardAllocate from './ForwardAllocate'
export default {
  name: 'FormLayout',
  components: {
    EditRequisition,
    ForwardAllocate
  },
  props: ['id', 'requisition_id'],
  created () {
    if (this.id) {
      this.getRequisitionDetails()
    }
  },
  data () {
    return {
      modalType: -111,
      forward_type: '',
      modalTitle: '',
      application_id: '',
      isEdit: true,
      requisition_edit: 0,
      loading: false,
      organizationName: '',
      requistion: '',
      requistion_details: '',
      items: [],
      approval_notes: '',
      approve: {
        org_id: this.$store.state.Auth.authUser.org_id,
        office_id: this.$store.state.Auth.authUser.office_id,
        requisition_id: this.$props.requisition_id,
        scheme_application_id: this.$props.id,
        status: 9
      }
    }
  },
  computed: {
    columns () {
      const labels = [
          this.$t('pump_install.category'),
          this.$t('pump_install.item_name'),
          this.$t('pump_install.measurement_unit'),
          this.$t('pump_install.stock_quantity'),
          this.$t('pump_install.stock_out_quantity'),
          this.$t('pump_install.current_stock')
        ]

      const keys = [
          { key: 'category' },
          { key: 'item_name' },
          { key: 'measurement_unit' },
          { key: 'stock_quantity' },
          { key: 'stock_out_quantity' },
          { key: 'current_stock' }
        ]

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async getRequisitionDetails () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, requisitionDetails + '/' + this.$props.id + '/' + this.$props.requisition_id)
      if (result.success) {
        this.requistion = result.data.requistion
        this.requisition_edit = result.data.requisition_edit
        this.approval_notes = result.data.approval_notes
        this.items = []
        this.items = this.formatList(result.data.requistion_details)
        this.getOrganization(this.requistion.org_id)
      }
      this.loading = false
    },
    getOrganization (orgId) {
      const orgList = this.$store.state.orgList
      const tmpOrg = orgList.find(org => org.value === orgId)
      this.organizationName = this.$i18n.locale === 'bn' ? tmpOrg.text_bn : tmpOrg.text_en
    },
    getOfficeName (officeId) {
      const officeList = this.$store.state.commonObj.officeList
      const tmpOffice = officeList.find(office => office.value === officeId)
      return (this.$i18n.locale === 'bn') ? tmpOffice.text_bn : tmpOffice.text_en
    },
    formatList (data) {
      if (this.$i18n.locale === 'bn') {
        const listData = data.map(item => {
          const isActive = { isActive: true }
          const tmpCategory = { category: item.category_name_bn }
          const tmpItemName = { item_name: item.item_name_bn }
          const tmpUnit = { measurement_unit: item.unit_name_bn }
          const tmpStock = { stock_quantity: this.$n(item.stock_quantity) }
          const tmpStockOut = { stock_out_quantity: this.$n(item.stock_out_quantity) }
          const tmpCurrentStock = { current_stock: item.stock_quantity > 0 ? this.$n(parseFloat(item.stock_quantity - item.stock_out_quantity)) : this.$n(0) }
          return Object.assign({}, isActive, tmpCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, tmpCurrentStock)
        })
        return listData
      } else {
        const listData = data.map(item => {
          const isActive = { isActive: true }
          const tmpCategory = { category: item.category_name }
          const tmpItemName = { item_name: item.item_name }
          const tmpUnit = { measurement_unit: item.unit_name }
          const tmpStock = { stock_quantity: this.$n(item.stock_quantity) }
          const tmpStockOut = { stock_out_quantity: this.$n(item.stock_out_quantity) }
          const tmpCurrentStock = { current_stock: item.stock_quantity > 0 ? this.$n(parseFloat(item.stock_quantity - item.stock_out_quantity)) : this.$n(0) }
          return Object.assign({}, isActive, tmpCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, tmpCurrentStock)
        })
        return listData
      }
    },
    async approveRequisition () {
      this.loading = true
      const result = await RestApi.postData(irriSchemeServiceBaseUrl, requisitionApprove, this.approve)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      }
    },
    editConfirmation () {
      this.modalType = 1
      this.isEdit = true
      this.modalTitle = this.$t('globalTrans.edit')
      this.application_id = this.$props.id
      this.requisition_id = this.$props.requisition_id
      this.$swal({
        title: this.$t('pump_install.requisitionEditMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
        } else {
          this.isEdit = false
        }
      })
    },
    forwardAllocate (type) {
      // type 1=Forward, 2=Allocate
      this.modalTitle = (type === 1) ? this.$t('pump_install.forward') : this.$t('pump_install.allocate')
      this.modalType = 2
      this.forward_type = type
      this.application_id = this.$props.id
      this.requisition_id = this.$props.requisition_id
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
