<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Pump Type" vid="pump_type_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pump_type_id"
                              slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                    {{ $t('pump_install.pump_type') }} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                  plain
                                  v-model="requisition.pump_type_id"
                                  id="pump_type_id"
                                  :options="getPumpType"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6">
                          <ValidationProvider name="Requisition ID" vid="requisition_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="requisition_id"
                              slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                    {{ $t('pump_install.requisition_id') }} <span class="text-danger">*</span>
                                  </template>
                                <b-form-input
                                  readonly
                                  v-model="requisition.requisition_id"
                                  id="requisition_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Remarks" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks"
                              slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                    {{ $t('pump_install.remarks') }} <span class="text-danger">*</span>
                                  </template>
                                <b-form-input
                                  v-model="requisition.remarks"
                                  id="remarks"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Remarks (Bn)" vid="remarks_bn" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks_bn"
                              slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                    {{ $t('pump_install.remarks_bn') }} <span class="text-danger">*</span>
                                  </template>
                                <b-form-input
                                  v-model="requisition.remarks_bn"
                                  id="remarks_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Requisition Date" vid="requisition_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="requisition_date"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('pump_install.requisition_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    class="date-picker"
                                    v-model="requisition.requisition_date"
                                    placeholder="Select Date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <p style="font-weight:bold; color:gray; text-transform:uppercase">{{ $t('select')+' '+$t('item') }}</p>
                      <hr style="margin-top:-15px">
                      <b-row v-for="(item,index) in requisition.items" :key="index">
                        <b-col lg="3" sm="4">
                          <ValidationProvider name="Category" vid="category_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="category_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('irrigation_config.category') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="category_id"
                                  v-model="item.category_id"
                                  :options="categoryList"
                                  @change="getItemList(index)"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Item" vid="item_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="item_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('irrigation_config.item') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="item_id"
                                  v-model="item.item_id"
                                  :options="item.itemsList"
                                  @change="getStock(index)"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Unit" vid="unit_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="unit_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('irrigation_config.unit') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  readonly
                                  id="unit_id"
                                  v-model="item.unit_id"
                                  :options="unitList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="stock" vid="stock">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="stock"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('pump_install.stock') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  readonly
                                  id="stock"
                                  v-model="item.stock"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="4">
                          <ValidationProvider name="Qty" vid="quantity" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12 p-0 m-0"
                              label-for="quantity"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('pump_install.quantity') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="quantity"
                                v-model.number="item.quantity"
                                @input="checkStock(index)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                          <b-col lg="1" sm="4">
                            <b-form-group>
                            <br>
                            <b-button class="btn btn-outline-danger mt-3" v-if="index !== 0" variant=" iq-bg-danger" size="sm" @click.prevent="removeItem(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            <b-button class="btn btn-outline-success mt-3" v-if="index === 0" variant=" iq-bg-success" size="sm" @click="addMore"><i class="ri-add-line m-0"></i></b-button>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { getRequisitionIdUrl, requisitionStore } from '../../api/routes'
import IrrigationMixin from '../../../../../mixins/irrigation-dropdown'
import flatpickr from 'flatpickr'

export default {
  name: 'FormLayout',
  mixins: [IrrigationMixin],
  props: ['id', 'org_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      requisition: {
        scheme_application_id: this.$props.id,
        pump_type_id: 0,
        requisition_id: '',
        requisition_date: '',
        remarks: '',
        remarks_bn: '',
        id_serial: 0,
        org_id: this.$props.org_id,
        office_id: this.$store.state.Auth.authUser.office_id,
        items: [
          {
            category_id: 0,
            item_id: 0,
            unit_id: 0,
            stock: 0,
            quantity: '',
            itemsList: []
          }
        ]
      }
    }
  },
  created () {
    if (this.id) {
      this.getRequisitionId()
    }
    this.org_id = this.$props.org_id
    this.office_id = this.$store.state.Auth.authUser.office_id
  },
  computed: {
    getPumpType: function () {
      return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0 && item.org_id === this.org_id)
    },
    getHorsePower: function () {
      return this.$store.state.IrriConfig.commonObj.horsePower
    },
    categoryList: function () {
      return this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0 && item.org_id === this.org_id)
    },
    unitList: function () {
     return this.$store.state.IrriConfig.commonObj.unitList.filter(item => item.status === 0)
    }
  },
  methods: {
    getRequisitionId () {
      this.loading = true
      RestApi.getData(irriSchemeServiceBaseUrl, getRequisitionIdUrl).then(response => {
        this.requisition.requisition_id = response.data
        this.loading = false
      })
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, requisitionStore, this.requisition)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    removeItem (index) {
      this.requisition.items.splice(index, 1)
    },
    addMore (index) {
      const tempItem = {
        category_id: 0,
        item_id: 0,
        unit_id: 0,
        quantity: '',
        itemsList: []
      }
      this.requisition.items.push(tempItem)
      // const key1 = parseInt(this.requisition.items.length - 1)
      // const item = this.requisition.items[key1]
      // let isEmpty = true
      // Object.keys(item).map(key => {
      //   if (key !== 'itemList') {
      //     if (item[key] === '' || item[key] === 0) {
      //       isEmpty = false
      //     }
      //   }
      // })
      // if (isEmpty === true) {
      //   this.requisition.items.push(tempItem)
      // }
    },
    categotyList () {
      return this.$store.state.IrriConfig.commonObj.categoryList.filter(item => item.status === 0)
    },
    getItemList (index) {
      const requisition = this.requisition.items[index]
      const orgId = this.org_id
      const categoryId = requisition.category_id
      const itemList = this.$store.state.IrriConfig.commonObj.itemList
      const itemData = itemList.filter(
        item => item.org_id === orgId &&
        item.category_id === categoryId &&
        item.status === 0
      )
      this.requisition.items[index].itemsList = itemData
      const tmp = this.requisition.items
      this.requisition.items = []
      this.requisition.items = tmp
    },
    getStock (index) {
      const requisition = this.requisition.items[index]
      const orgId = this.org_id
      const officeId = this.office_id
      const itemId = requisition.item_id
      const stock = this.$store.state.IrriConfig.commonObj.pumpStock
      const itemData = stock.find(tmpStock => (tmpStock.office_id === officeId && tmpStock.item_id === itemId))
      // requisition.stock = itemData[0].quantity
      requisition.stock = itemData.quantity
      const unitList = this.$store.state.IrriConfig.commonObj.unitList
      const unitData = unitList.find(unit => unit.org_id === orgId)
      requisition.unit_id = unitData.value
    },
    setEmptyVal () {
      this.requisition.items.map(item => {
        item.category_id = 0
        item.item_id = 0
        item.unit_id = 0
        item.stock = 0
        item.quantity = ''
        item.itemsList = []
      })
    },
    checkStock (index) {
      const requisition = this.requisition.items[index]
      if (requisition.quantity > requisition.stock) {
        requisition.quantity = requisition.stock
      }
    }
  }
}
</script>
